<script>
import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      list: [],
      submitLoading: false,
      deviceId: null,
    };
  },
  methods: {
    ...mapActions("user", ["deviceList", "unbindDevice"]),
    async getList() {
      try {
        this.loading = true;
        this.dialogVisible = true;
        const { data } = await this.deviceList({ id: this.id });
        this.list = data;
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      try {
        this.submitLoading = true;
        console.log(this.deviceId);
        const { res_info } = await this.unbindDevice({ id: this.deviceId });
        if (res_info !== "ok") return;
        this.$message.success("解绑成功");
      } finally {
        this.dialogVisible = false;
        this.submitLoading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="设备列表"
      width="20%"
    >
      <span>
        <el-table v-loading="loading" :data="list" style="width: 100%">
          <el-table-column>
            <template slot-scope="scope">
              <el-radio v-model="deviceId" :label="scope.row.id" />
            </template>
          </el-table-column>
          <el-table-column label="设备名称" prop="device_model" />
        </el-table>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="submitLoading" type="primary" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-link type="primary" @click="getList">解绑设备</el-link>
  </div>
</template>

<style scoped></style>